/**
 * toggle
 */
const toggle = {
  cfg:  {
      name: 'toggle',
      selectors: {
          toggle: '.js-toggle',
          closers: '.o-pageContent, .o-pageFooter'
      }
  },

  init() {
      const toggle = document.querySelector('#toggle');

      if(toggle) {
      window.onload = function() {

        // const closers = document.querySelectorAll(this.cfg.toggle.closers);

          toggle.addEventListener('click',()=> {
              document.body.classList.toggle('overlay-is-open')

              toggle.scrollIntoView({behavior: 'smooth'});


              // closers.addEventListener('click',function (event) {
              //     document.body.classList.remove('overlay-is-open')
              // })
          })
      }
    }
  }
};

export default toggle;
