/**
 * simpleParallax
 */

import simpleParallax from 'simple-parallax-js';

const parallax = {
  cfg: {
    trigger: '.parallax img'
  },

  init() {
    window.addEventListener('load', () => {
        const images = document.querySelectorAll(this.cfg.trigger);
        new simpleParallax(images, {
            delay: 3,
            orientation: 'up',
            scale: 1.1,
            overflow: false,
        });
    })
  }
}

export default parallax
