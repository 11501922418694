0/**
 * Image Slider
 */

import Flickity from 'flickity-fade'

const pageSlider = {
  cfg: {
    slider: '.js-pageSlider',
    sliderOptions: {
      cellAlign: 'center',
      contain: false,
      draggable: true,
      fade: false,
      autoPlay: 4000,
      friction: 0.25,
      selectedAttraction: 0.015,
      groupCells: 1,
      setGallerySize: false,
      prevNextButtons: true,
      pageDots: true,
      wrapAround: true,
      // arrowShape: { 
      //   x0: 10,
      //   x1: 60, y1: 50,
      //   x2: 65, y2: 45,
      //   x3: 20
      // }
      // watchCSS: true
    }
  },

  init() {
    window.addEventListener('load', () => {
      const sliders = [...document.querySelectorAll(this.cfg.slider)]
      if (sliders) {
        sliders.forEach((slider) => {

          // const prev = slider.parentNode.querySelector('.prev');
          // const next = slider.parentNode.querySelector('.next');
  
          const flkty = new Flickity(slider, this.cfg.sliderOptions)
          flkty.resize()

          setTimeout(() => {
            flkty.resize()
            slider.classList.add('is-ready')
          }, 1000)


          // // previous
          // prev.addEventListener('click', function () {
          //   flkty.previous(true, false);
          // });

          // // next
          // next.addEventListener('click', function () {
          //   flkty.next(true, false);
          // });


        })
      }
    })
  }
}

export default pageSlider
