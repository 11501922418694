/**
 * imageGallery (Photoswipe
 */

// Dependencies
import PhotoSwipeLightbox from '../../../../../node_modules/photoswipe/dist/photoswipe-lightbox.esm';
import PhotoSwipe from '../../../../../node_modules/photoswipe/dist/photoswipe.esm';

/* eslint-disable */
const imageGallery = {

  init() {
    const galleries = document.querySelectorAll('.js-imageGallery');

    if (galleries) {
        const lightbox = new PhotoSwipeLightbox({
          gallery: galleries,
          children: 'a',
          bgOpacity: 0.98,
          pswpModule: PhotoSwipe
        });

        lightbox.init();
    }
  },
};
/* eslint-enable */
export default imageGallery;
