const contactForm = {
  /* eslint-disable */
  cfg: {
    form: '.js-form',
  },
  init() {
    window.addEventListener('load', function () {
      const form = document.querySelector('form');
      if (form) {

        const message = document.getElementById('message');
        // cache the existing html
        var messageInnerHTML = message.innerHTML;
        console.log(messageInnerHTML);
        var fields = {};


        form.querySelectorAll('[name]').forEach(function (field) {
          fields[field.name] = field;
        });

        // Displays all error messages and adds 'error' classes to the form fields with
        // failed validation.
        var handleError = function (response) {
          var errors = [];
          // console.log(response);
          for (var key in response) {
            if (!response.hasOwnProperty(key)) continue;
            if (fields.hasOwnProperty(key)) fields[key].classList.add('is-invalid');

            Array.prototype.push.apply(errors, response[key]);
          }
          message.innerHTML = errors.join('<br>');
        };

        var onload = function (e) {
          if (e.target.status === 200) {
            message.classList.remove('is-error');
            message.innerHTML = messageInnerHTML;
            message.classList.add('is-success');
            form.classList.add('is-send');
          } else {
            message.classList.add('is-error');
            handleError(JSON.parse(e.target.response));
          }
        };

        var submit = function (e) {
          e.preventDefault();
          var request = new XMLHttpRequest();
          request.open('POST', e.target.action);
          request.onload = onload;
          request.send(new FormData(e.target));

          // console.log(request);
          // Remove all 'error' classes of a possible previously failed validation.
          for (var key in fields) {
            if (!fields.hasOwnProperty(key)) continue;
            fields[key].classList.remove('error');
          }

          document.querySelector('#formWrapper').remove();
        };
        form.addEventListener('submit', submit);
      }
    });
  },
  /* eslint-enable */
};

export default contactForm;
